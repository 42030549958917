<template>
  <div>
    <vs-table
      search
      stripe
      border
      description
      :sst="true"
      :data="table.data"
      :max-items="table.length"
      :total="table.total"
      @search="handleSearch"
      @change-page="handleChangePage"
      @sort="handleSort"
    >
      <template slot="header">
        <!-- <vs-dropdown vs-trigger-click class="cursor-pointer">
					<div class=" p-3 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium">
						<span class="mr-2">{{ this.table.start }} - {{ this.table.end }} of {{ this.table.total }}</span>
						<feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
					</div>
					<vs-dropdown-menu>
						<vs-dropdown-item
							v-for="item in table.limits"
							:key="item"
							@click="handleChangelength(item)"
							>
							<span>{{ item }}</span>
						</vs-dropdown-item>
					</vs-dropdown-menu>
				</vs-dropdown> -->
        <!-- &nbsp;
				<vx-tooltip text="Print">
                    <vs-button
                      color="black"
                      icon-pack="feather"
                      @click="print()"
                      icon="icon-printer"
                    />
                </vx-tooltip> -->
      </template>
      <template slot="thead">
        <vs-th sort-key="number">Row</vs-th>
        <vs-th>IO Code</vs-th>
        <vs-th sort-key="reference_code">Invoice Number</vs-th>
        <vs-th>Item</vs-th>
        <vs-th sort-key="note">Note</vs-th>
        <vs-th sort-key="status">Status</vs-th>
        <vs-th sort-key="created_at">Time</vs-th>
      </template>
      <template slot-scope="{ data }">
        <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
          <vs-td>{{ tr.number }}</vs-td>
          <vs-td>{{ JSON.parse(tr.json_data)[0] }}</vs-td>
          <vs-td>{{ JSON.parse(tr.json_data)[12] }}</vs-td>
          <vs-td>{{ JSON.parse(tr.json_data)[15] }}</vs-td>
          <vs-td>{{ tr.note }}</vs-td>
          <vs-td>{{ tr.status }}</vs-td>
          <vs-td>{{ dateFormat(tr.created_at) }}</vs-td>
        </vs-tr>
      </template>
    </vs-table>
    <vs-pagination
      style="padding-top: 5px"
      :total="table.totalPage"
      v-model="setPage"
    />
  </div>
</template>

<script>
import moment from "moment";
export default {
  props: ["selected"],
  components: {
    //
  },
  mounted() {
    //
  },
  data() {
    return {
      table: {
        data: [],
        length: 10,
        page: 1,
        search: "",
        order: "id",
        sort: "asc",
        total: 0,
        totalPage: 0,
        totalSearch: 0,
        limits: [10, 25, 50, 100, "All"],
        start: 1,
        end: 0,
      },
    };
  },
  methods: {
    dateFormat(val){
      return moment(val).add(-7, "h").utc().local().format("DD/MM/YYYY HH:mm")
    },
    dateFormatGMT7(date) {
      if (date) {
        // ini karena datanya berformat `date time`, bukan `datetimezone`
        // jadi GMT(+7) ditambah +7 =>utc offset = 14
        return moment(String(date)).format("DD/MM/YYYY HH:mm");
      }
    },
    handleSearch(searching) {
      this.table.search = searching;
      this.table.page = 1;
      this.getDataDetail();
    },
    handleChangePage(page) {
      this.table.page = page;
      this.getDataDetail();
    },
    handleSort(key, active) {
      this.table.order = key;
      this.table.sort = active;
      this.getDataDetail();
    },
    handleChangelength(val) {
      this.table.length = val == "All" ? this.table.total : val;
      this.table.page = 1;
      this.getDataDetail();
    },
    getDataDetail() {
      this.$vs.loading();
      let params = {
        log_activitas_id: this.selected,
        length: this.table.length,
        page: this.table.page,
        search: this.table.search,
        order: this.table.order,
        sort: this.table.sort,
        type: "free-goods",
      };
      console.log("params", params);
      this.$http
        .get("/api/v1/import/history-line", {
          params: params,
        })
        .then((resp) => {
          if (resp.status == "success") {
            this.table.total = resp.data.total_record;
            this.table.totalPage = resp.data.total_page;
            this.table.totalSearch = resp.data.total_record_search;
            this.table.length = resp.data.total_record_per_page;
            this.table.data = resp.data.records;
            this.setStartEnd();
            this.$vs.loading.close();
          } else {
            console.log(resp.message);
          }
        });
    },
    setStartEnd() {
      let valStart =
        this.table.length * this.table.page - this.table.length + 1;
      if (valStart > this.table.total) {
        valStart = 1;
      }
      if (this.table.total == 0) {
        valStart = 0;
      }
      let valEnd = this.table.length * this.table.page;
      if (valEnd > this.table.total) {
        valEnd = this.table.total;
      }
      if (this.table.totalSearch < this.table.total) {
        valEnd = this.table.totalSearch;
      }

      this.table.start = valStart;
      this.table.end = valEnd;
    },
    print() {
      let name = "import-log-print";
      let routeData = this.$router.resolve({
        name: name,
        params: {
          id: this.selected,
        },
      });
      window.open(routeData.href, "_blank");
    },
  },
  watch: {
    watchedProperties: function () {
      this.getDataDetail();
    },
  },
  computed: {
    watchedProperties() {
      return this.selected;
    },
    setPage: {
      get() {
        return 1;
      },
      set(val) {
        this.handleChangePage(val);
      },
    },
  },
};
</script>

 <style scoped>
.nonfixed {
  position: inherit;
  padding-left: 20px;
}
.core-enter-active {
  transition: all 0.3s ease;
}
.core-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}
.core-enter, .core-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
.core {
  -webkit-transform-origin-y: all 1s ease;
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -o-transition: all 1s ease;
  transition: all 1s ease;
}
.vs-con-table .vs-con-tbody .vs-table--tbody-table {
  font-size: 12px;
}
.vertical-divider {
  border-right: 1px solid #7367f0;
  /* min-height: 800px; */
  /* height: 100%; */
  -webkit-mask-position-y: fixed;
  /* padding: 5px; */
}
.vs-con-table.stripe .tr-values:nth-child(2n) {
  background: beige;
}
.colored {
  border: 1px solid #7367f0;
  position: fixed;
  left: 4%;
  top: 40%;
  max-width: 45%;
  z-index: 999999999999;
  background: antiquewhite;
  background-color: antiquewhite;
}
</style>